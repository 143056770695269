import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export async function successNotify(message: string, time: number) {
  return toast.success(message, {
    autoClose: time,
    closeButton: true
  })
}

export async function errorNotify(message: string, time: number) {
  return toast.error(message, {
    autoClose: time,
    closeButton: true
  })
}

export async function warningNotify(message: string, time: number) {
  return toast.warning(message, {
    autoClose: time,
    closeButton: true
  })
}

export async function infoNotify(message: string, style: string) {
  return toast.info(message,
    {
      position: 'top-right',
      bodyClassName: style,
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true
    })
}

export async function updateNotify(
  message: string,
  id: string | number,
  type: 'success' | 'error'
) {
  return toast.update(id, {
    render: message,
    type: type,
    isLoading: false,
    autoClose: 3000,
    closeButton: true
  })
}
