import { ButtonHTMLAttributes, forwardRef } from 'react'

import styles from './styles.module.css'

export type ButtonProps = {
  outline?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { children, outline = false, ...props },
  ref
) => {
  const style = outline ? 'outline' : 'button'

  return (
    <button className={styles[style]} {...props} ref={ref}>
      {!!children && children}
    </button>
  )
}

export default forwardRef(Button)
