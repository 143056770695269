import Image from 'next/image'
import { useRouter } from 'next/router'

import { Field, Formik } from 'formik'
import { signIn } from 'next-auth/react'

import 'react-toastify/dist/ReactToastify.css'
import Button from '../components/Button'

import styles from 'styles/Home.module.css'
import { warningNotify } from 'utils/toast/notifyToastify'

export default function SignIn() {
  const router = useRouter()

  if (router.query.error === 'unauthorized') {
    warningNotify('Sua sessão expirou, faça login novamente.', 7000)
  }

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.boxImg}>
          <Image
            src="/img/Logo.png"
            alt="Charlie Logo"
            width={320}
            height={104}
          />
        </div>

        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            await signIn('credentials', {
              username: values.username,
              password: values.password,
              callbackUrl: '/portaria'
            })

            setSubmitting(false)
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.containerForm}>
                <div>
                  <div className={styles.rowInput}>
                    <label htmlFor="username">
                      <Field
                        name="username"
                        aria-label="enter your email"
                        aria-required="true"
                        type="text"
                        placeholder="Nome de usuário"
                        autoComplete="username"
                        className={styles.input}
                      />
                    </label>
                  </div>

                  <div className={styles.rowInput}>
                    <label htmlFor="password">
                      <Field
                        name="password"
                        aria-label="enter your password"
                        aria-required="true"
                        type="password"
                        placeholder="Senha"
                        autoComplete="current-password"
                        className={styles.input}
                      />
                    </label>
                  </div>

                  <Button type="submit">
                    {formik.isSubmitting ? 'Carregando...' : 'Login'}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </main>
    </div>
  )
}
